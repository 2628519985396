import * as React from 'react';
import { Navbar, NavItem } from 'reactstrap';
import './css/Footer.css';

export const Footer = (props: any) => {

    return (
        <footer>
            <Navbar className="footerBottom d-flex justify-content-between navbar-expand-sm navbar-toggleable-xsm border-primary mb-0" light style={{ height: 40, backgroundColor: 'rgba(92, 111, 123) !important' }}>
                <NavItem className="d-flex align-items-center">
                    <p className="" style={{ margin: 0, color: 'white' }}>Meritus Health Copyright &copy; 2021</p>
                </NavItem>
                <NavItem className="d-flex align-items-center flex-row-reverse">
                    <p style={{ margin: 0, color: 'white' }}>Made with &hearts; in Hagerstown, MD</p>
                </NavItem>
            </Navbar>
        </footer>
    );
}