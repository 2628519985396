import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../store';
import './css/Pagination.css'

export const Pagination = (props: { data: any, RenderComponent: any, title: any, pageLimit: any, dataLimit: any }) => {

    const dataLimit = 20;
    const thankyousList = useSelector((state: ApplicationState) => state.media!.thankyous)
    const [pages] = useState(Math.ceil(thankyousList.length / dataLimit));
    const [currentPage, setCurrentPage] = useState(1);
    const firstUpdate = useRef(true);

    useEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }
        console.log('here');
        window.scrollTo({ behavior: 'smooth', top: 1100 });
    }, [currentPage]);

    function goToNextPage() {
        setCurrentPage((page) => page + 1);
    }

    function goToPreviousPage() {
        setCurrentPage((page) => page - 1);
    }

    function changePage(event: any) {
        const pageNumber = Number(event.target.textContent);
        setCurrentPage(pageNumber);
    }

    const getPaginatedData = () => {
        const startIndex = currentPage * dataLimit - dataLimit;
        const endIndex = startIndex + dataLimit;
        return props.data.slice(startIndex, endIndex);
    };

    const getPaginationGroup = () => {
        let start = Math.floor((currentPage - 1) / props.pageLimit) * props.pageLimit;
        return new Array(props.pageLimit).fill(0).map((_, idx) => start + idx + 1);
    };

    return (
        <div>
            <div className="dataContainer">
                {getPaginatedData().map((d: any, idx: any) => (
                    <props.RenderComponent key={idx} data={d} />
                ))}
            </div>
            <div className="pagination">
                <button
                    onClick={goToPreviousPage}
                    className={`prev ${currentPage === 1 ? 'disabled' : ''}`}
                >
                    prev
                </button>
                {getPaginationGroup().map((item, index) => (
                    <button
                        key={index}
                        onClick={changePage}
                        className={`paginationItem ${currentPage === item ? 'active' : null}`}
                    >
                        <span>{item}</span>
                    </button>
                ))}
                <button
                    onClick={goToNextPage}
                    className={`next ${currentPage === pages ? 'disabled' : ''}`}
                >
                    next
                </button>
            </div>
        </div>
  );
}