import * as React from 'react';
import { Navbar, NavbarBrand } from 'reactstrap';
import './css/NavMenu.css';

export const NavMenu = (props: any) => {

    return (
        <header className="">
            <Navbar className="navbar-expand-sm navbar-toggleable-sm border-bottom " light style={{ fontSize: 20 }}>
                <NavbarBrand className="logo" href="https://www.meritushealth.com/">
                    {'       '}
                    <img
                        alt=""
                        src="merituslight.png"
                        width="130"
                        height="100"
                        className="mainLogo d-inline-block align-top"
                    />{' '}
                </NavbarBrand>
            </Navbar>
        </header>
    );
}
