import * as React from 'react';
import { Footer } from './Footer';
import { NavMenu } from './NavMenu';

export default (props: { children?: React.ReactNode }) => (
    <React.Fragment>
        <NavMenu />
        <div className="w-100" style={{ margin: 0 }}>
            {props.children}
        </div>
        <Footer/>
    </React.Fragment>
);
