import { Action, Reducer } from 'redux';
import { AppThunkAction } from './';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface FormState {
	resp: string;
	clientIP: string;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

export interface SubmitForm { type: 'SUBMIT_FORM', response: string }
export interface ApproveForm { type: 'APPROVE_FORM', response: string }
export interface DenyForm { type: 'DENY_FORM', response: string }
export interface GetIP { type: 'GET_IP', clientIP: string }

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction = SubmitForm | ApproveForm | DenyForm | GetIP;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
	submitForm: (creds: any): AppThunkAction<KnownAction> => (dispatch) => {
		let config = {
			method: 'POST',
			body: creds
		}
		fetch("api/form/submitForm/", config)
			.then(response => response.text() as Promise<string>)
			.then(data => {
				dispatch({ type: 'SUBMIT_FORM', response: data })
			});
	},
    approveForm: (creds: any): AppThunkAction<KnownAction> => (dispatch) => {
		let config = {
			method: 'POST',
			headers: { 'Content-Type':'application/x-www-form-urlencoded' },
			body: `encryptedString=${creds.encryptedString}`
		}
		fetch("api/form/approveForm/", config)
			.then(response => response.text() as Promise<string>)
			.then(data => {
				dispatch({ type: 'APPROVE_FORM', response: data })
			});
	},
    denyForm: (creds: any): AppThunkAction<KnownAction> => (dispatch) => {
		let config = {
			method: 'POST',
			headers: { 'Content-Type':'application/x-www-form-urlencoded' },
			body: `encryptedString=${creds.encryptedString}`
		}
		fetch("api/form/denyForm/", config)
			.then(response => response.text() as Promise<string>)
			.then(data => {
				dispatch({ type: 'DENY_FORM', response: data })
			});
	},
	getIP: (): AppThunkAction<KnownAction> => (dispatch) => {
		fetch('https://geolocation-db.com/json/')
			.then(response => response.json())
			.then(data => {
				dispatch({ type: 'GET_IP', clientIP: data.IPv4 });
			});
	}
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

export const reducer: Reducer<FormState> = (state: FormState | undefined, incomingAction: Action): FormState => {
    if (state === undefined) {
        return {
			resp: "",
			clientIP: ""
            };
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'SUBMIT_FORM':
            return {
				resp: action.response,
				clientIP: state.clientIP
            };
        case 'APPROVE_FORM':
            return {
				resp: action.response,
				clientIP: state.clientIP
            };
        case 'DENY_FORM':
            return {
				resp: action.response,
				clientIP: state.clientIP
			};
		case 'GET_IP':
			return {
				resp: state.resp,
				clientIP: action.clientIP
            }
        default:
            return state;
    }
};
