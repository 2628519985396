import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ApplicationState } from '../store';
import { actionCreators } from '../store/Form';
import './css/DonationForm.css'

export const DonateForm = () => {
    const dispatch = useDispatch();
    const ip = useSelector((state: ApplicationState) => state.form!.clientIP);

    if (ip === "") { dispatch(actionCreators.getIP()); }

    React.useLayoutEffect(() => {
        const scriptAuth = document.createElement('script');

        scriptAuth.innerHTML = "BBDonorFormLoader.newBlackbaudDonationFormZoned('renxt', 'p-I_DpLewaAUiBo6cwK7sjmQ', 'b490cf2a-538f-445b-8837-34442dad4274', 'usa')";
        scriptAuth.async = true;

        document.body.appendChild(scriptAuth);

        return () => {
            document.body.removeChild(scriptAuth);
        }
    }, []);

    return (
        <div className="DonateForm" style={{
            backgroundImage: "url('collagebg.png')",
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat'
        }}>
            <div className="donationContainer" id="blackbaud-donation-form_b490cf2a-538f-445b-8837-34442dad4274"></div>
        </div>
    );
}