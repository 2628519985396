import * as React from 'react';
import { useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import { useSelector } from 'react-redux';
import { Carousel } from 'react-responsive-carousel';
import { ApplicationState } from '../store';
import * as MediaStore from "../store/Media";
import './css/VideoCarousel.css'
import 'react-responsive-carousel/lib/styles/carousel.min.css';

export const VideoCarousel = (props: any) => {

    const videoList = useSelector((state: ApplicationState) => state.media!.videos)
    const [currentSlide, setCurrentSlide] = useState(0);

    const VideoSlide = (vid: { index: any, currentSlide: any, url: string, goNextVideo: any, caption: string}) => {
        const videoRef = useRef(null);
        return (
            <div>
                <ReactPlayer
                        className="player"
                        ref={videoRef}
                        width="100%"
                        height="600px"
                        url={vid.url}
                        play={vid.index == vid.currentSlide}
                        controls
                        onEnded={vid.goNextVideo}
                        style={{ borderRadius: '2em' }}
                        light={(vid.index === 0) ? false : true}
                        config={{
                            youtube: {
                                playerVars: { modestbranding: 1}
                            }
                        }}
                />
                <p className='videoCaption'>{vid.caption}</p>
            </div>
        );
    };

    const goNextVideo = () => {
        setCurrentSlide(currentSlide + 1);
    };


    if (videoList.length === 0) {
        return (<div />)
    }
    else {

        const getVideoThumb = (videoId: string) => `https://img.youtube.com/vi/${videoId}/default.jpg`;

        const getVideoId = (url: string) => url.substr('https://youtu.be/'.length, url.length);

        console.log(videoList);
        const customRenderThumb = () =>
            videoList.map((vid: MediaStore.Video) => {
                console.log(vid.url);
                const videoId = getVideoId(vid.url);
                console.log(videoId);
                return <img src={getVideoThumb(videoId)} />;
            });
        

        return (
            <Carousel
                infiniteLoop
                dynamicHeight={true}
                renderThumbs={customRenderThumb}
            >
                {videoList &&
                    videoList.map((video: MediaStore.Video, index: any) => {
                        return (
                            <VideoSlide
                                key={index}
                                index={index}
                                currentSlide={currentSlide}
                                goNextVideo={goNextVideo}
                                url={video.url}
                                caption={video.description}
                            />
                        );
                    })}
            </Carousel>
        );
    }
}