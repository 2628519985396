import * as React from 'react';
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { Row, Form, Button, FormGroup, Label, Input } from 'reactstrap';
import { ApplicationState } from '../store';
import { actionCreators } from '../store/Form';
import { actionCreators as FormActionCreators } from '../store/Form';
import './css/ThankYouForm.css'
import { IoCloseSharp } from 'react-icons/io5';

export const ThankYouForm = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const [error, setError] = useState(false);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const ip = useSelector((state: ApplicationState) => state.form!.clientIP);
    const [submitted, setSubmitted] = useState(false);

    React.useEffect(() => { dispatch(actionCreators.getIP()); }, []);

    //Handle Changes
    function nameChange(e: { target: { value: React.SetStateAction<string>; }; }) {
        setName(e.target.value.toString());
    }

    function emailChange(e: { target: { value: React.SetStateAction<string>; }; }) {
        setEmail(e.target.value.toString());
    };

    function messageChange(e: { target: { value: React.SetStateAction<string>; }; }) {
        setMessage(e.target.value.toString());
    };

    //Submit Button actions
    const submitForm = async (e: any) => {
        e.preventDefault();

        if (submitted) {
            history.push('/donate');
        }

        else {
            if (name === "" || message === "" || email === "") {
                setError(true);
                return;
            }

            let inputForm = new FormData();

            inputForm.append("ip", ip);
            inputForm.append("name", name);
            inputForm.append("email", email);
            inputForm.append("message", message);

            setSubmitted(true);
            dispatch(FormActionCreators.submitForm(inputForm))
        }
    }

    const redirect = () => {
        history.push("/thanks");
    }

    return (
        <div className="ThankYouForm" style={{
            backgroundImage: "url('collagebg.png')",
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat'
        }}>
            {submitted ?
                <Form className="submittedForm" onSubmit={(e) => history.push('/thanks/donate')}>
                    <Row style={{ width: '100%', justifyContent: 'end' }}>
                        <button className="redirectButton" onClick={redirect}><IoCloseSharp size='30' /></button>
                    </Row>
                    <h2 style={{ fontWeight: 'bold', textAlign: 'center', fontSize: 28 }}>Thank you for the card!</h2>
                    <hr />
                    <p className="submittedSubtext" style={{ textAlign: 'center', fontSize: 20 }}>We have received your thank you card and it will be posted to our site shortly. Please consider making a donation to our health care heroes, 100% of the proceeds will be used for employee wellbeing events and initiatives.</p>
                    <hr />
                    <Row style={{ justifyContent: 'center' }}>
                        <Button style={{ margin: 5, width: 200, height: 33, fontSize: 16, borderRadius: '2em', borderColor: '#28B463', backgroundColor: '#28B463' }} href="/thanks/donate">Donate Now</Button>
                    </Row>

                </Form>
                :
                <Form className="mainForm" onSubmit={(e) => submitForm(e)}>
                    <h2 style={{ fontWeight: 'bold', textAlign: 'center', fontSize: 40 }}>Give Thanks</h2>
                    <p className="formSubtext" style={{ textAlign: 'center', fontSize: 20, paddingLeft: 20, paddingRight: 20 }}>Send a message of gratitude, encouragement, or positivity to the health care heroes at Meritus Health.</p>
                    <hr />
                    {error &&
                        <p style={{ backgroundColor: 'darkred', color: 'white', borderRadius: '2em', textAlign: 'center' }}>&nbsp;Please fill out all required information.</p>
                    }
                    <FormGroup>
                        <Label for="email">Your Email *</Label>
                        <Input type="email" name="email" id="exampleEmail" placeholder="This will not be displayed with your card." value={email} onChange={emailChange} />
                    </FormGroup>
                    <FormGroup>
                        <Label for="name">Your First Name *</Label>
                        <Input type="text" name="name" id="name" placeholder="Use your first name only for your privacy." value={name} onChange={nameChange} />
                    </FormGroup>
                    <FormGroup>
                        <Label for="message">Your Message *</Label>
                        <Input type="textarea" name="message" id="message" placeholder="Please do not include the names of Meritus Health staff in your message for their privacy." value={message} onChange={messageChange} />
                    </FormGroup>
                    <Row style={{ justifyContent: 'center' }}>
                        <button className="submitButton">Submit</button>
                    </Row>
                </Form>
            }
        </div>
    );
}