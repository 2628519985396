import { Action, Reducer } from 'redux';
import { AppThunkAction } from './';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface MediaState {
    videos: Video[];
    thankyous: ThankYou[];
}

export interface Video {
    url: string;
    description: string;
}

export interface ThankYou {
    firstName: string;
    message: string;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

export interface GetVideos { type: 'GET_VIDEOS', videos: Video[] }
export interface GetThankYous { type: 'GET_THANKYOUS', thankyous: ThankYou[] }

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction = GetVideos | GetThankYous;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    getVideos: (): AppThunkAction <KnownAction> => (dispatch) => {
        fetch("api/media/getVideos")
            .then(response => response.json() as Promise<Video[]>)
            .then(data => {
                dispatch({ type: 'GET_VIDEOS', videos: data });
            });
    },
    getThankYous: (): AppThunkAction<KnownAction> => (dispatch) => {
        fetch("api/media/getThankYous")
            .then(response => response.json() as Promise<ThankYou[]>)
            .then(data => {
                console.log(data);
                dispatch({ type: 'GET_THANKYOUS', thankyous: data });
            });
    }
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

export const reducer: Reducer<MediaState> = (state: MediaState | undefined, incomingAction: Action): MediaState => {
    if (state === undefined) {
        return {
            videos: [],
            thankyous: []
            };
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'GET_VIDEOS':
            return {
                videos: action.videos,
                thankyous: state.thankyous
            };
        case 'GET_THANKYOUS':
            return {
                videos: state.videos,
                thankyous: action.thankyous
            };
        default:
            return state;
    }
};