import * as React from 'react';
import { useParams } from 'react-router';
import { useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { ApplicationState } from '../store';
import { actionCreators as FormActionCreators } from '../store/Form';
import './css/DenyForm.css'

export const ApproveForm = () => {

    const dispatch = useDispatch();
    const urlParams = useParams();
    const encryptedString = String(Object.values(urlParams)[0]);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isFailure, setIsFailure] = useState(false);
    const resp = useSelector((state: ApplicationState) => state.form!.resp);
    const firstUpdate = useRef(true);

    const [modal, setModal] = useState(true);
    const toggle = () => setModal(!modal);

    React.useLayoutEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }
        if (resp == "Success") {
            setIsSuccess(true);
            setIsFailure(false);
        }
        if (resp == "Failure" || resp == "input failure") {
            setIsFailure(true);
            setIsSuccess(false);
        }
        console.log(resp);
    }, [resp]);

    React.useEffect(() => {
        if (firstUpdate.current == false) {
            dispatch(FormActionCreators.approveForm({ encryptedString: encryptedString }));
        }
    }, []);

    return (
        <div className="modal-backdrop" >
                <Modal isOpen={modal} >
                <ModalHeader style={{ color: "white", backgroundColor: "dodgerblue", fontSize: 26 }}>Approval</ModalHeader>
                <ModalBody className="pend">
                    { isSuccess && 
                        <p style={{ margin: 0, fontSize: 20 }}>Complete! You may now close this page.</p>
                    }
                    { isFailure &&
                        <p style={{ margin: 0, fontSize: 20 }}>Error! This thank you has previously been approved OR has previously been denied.</p>
                    }
                    { (!isFailure && !isSuccess) &&
                        <p style={{ margin: 0, fontSize: 20 }}>Processing..</p>
                    }
                </ModalBody>
                </Modal>
        </div>
    );
}