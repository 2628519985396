import * as React from 'react';
import ReactPlayer from 'react-player';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { Card, CardTitle, CardText, Button, Container, ModalHeader, ModalBody, Modal } from 'reactstrap';
import { ApplicationState } from '../store';
import { actionCreators } from '../store/Media';
import './css/Reflections.css'
import { Pagination } from './Pagination';
import { ThankYouCards } from './ThankYouCards';
import { VideoCarousel } from './VideoCarousel';
import { FaPencilAlt, FaGlasses, FaHandHoldingUsd, FaRegPlayCircle } from 'react-icons/fa';
import { useState } from 'react';

export const Reflections = () => {
    const dispatch = useDispatch();
    const path = window.location.pathname;
    const thankyousList = useSelector((state: ApplicationState) => state.media!.thankyous);
    const history = useHistory();
    const max = Math.ceil(thankyousList.length / 20) > 5 ? 5 : Math.ceil(thankyousList.length / 20)
    const numPages = (thankyousList.length > 20) ? max : 1;

    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);
    const closeBtn = <button className="close text-white" style={{ backgroundColor: "transparent", borderColor: 'transparent' }} onClick={toggle}>&times;</button>;

    React.useEffect(() => { dispatch(actionCreators.getThankYous()); }, []);
    React.useEffect(() => { dispatch(actionCreators.getVideos()); }, []);

    let location = useLocation();

    React.useEffect(() => {
        const locSplit = location.pathname.split('/');

        if (locSplit[1] === "about") {
            setModal(true);
        }
    }, [location]);

    return (
        <div style={{ width: '100% !important', overflowX: 'hidden', overflowY: 'auto' }}>
            <Modal style={{ width: 'calc(100vh - 300px)' }} isOpen={modal} toggle={toggle}>
                <ModalHeader className="aboutheader text-white" toggle={toggle} close={closeBtn}>About the Project - Maulik Joshi, CEO</ModalHeader>
                <ModalBody className="aboutbody">
                    <ReactPlayer
                        className="player"
                        width='100%'
                        url="https://youtu.be/uzUAQQUjwvo"
                        controls
                        style={{ borderRadius: '2em' }}
                        config={{
                            youtube: {
                                playerVars: { modestbranding: 1 }
                            }
                        }}
                    />
                </ModalBody>
            </Modal>
            <section className="topSection">
                <img className="topImage" src='./reflections_banner9.png' />
                <img className="mobiletopImage" src='./mobile_reflections_banner.png' />
                <div className='halfoval'></div>
                {path === "/thanks" ?
                    <div className="desktopButtons">
                        <ul className="buttonContainer">
                            <Card className="desktopButtonCards">
                                <button className="invButton" onClick={() => setModal(true)}>
                                    <CardTitle className="desktopButtonTitle"><FaRegPlayCircle size='70' /></CardTitle>
                                    <hr />
                                    <CardText>About the Project</CardText>
                                </button>
                            </Card>
                            <Card className="desktopButtonCards">
                                <a className="invButton" href="/thanks#thankyouCards">
                                    <CardTitle className="desktopButtonTitle"><FaGlasses size='70' /></CardTitle>
                                    <hr />
                                    <CardText>See Thank You Cards</CardText>
                                </a>
                            </Card>
                            <Card className="desktopButtonCards">
                                <button className="invButton" onClick={() => history.push('/thanks/submit')}>
                                    <CardTitle className="desktopButtonTitle"><FaPencilAlt size='70' /></CardTitle>
                                    <hr />
                                    <CardText>Leave a Thank You</CardText>
                                </button>
                            </Card>
                            <Card className="desktopButtonCards">
                                <button className="invButton" onClick={() => history.push('/thanks/donate')}>
                                    <CardTitle className="desktopButtonTitle"><FaHandHoldingUsd size='70' /></CardTitle>
                                    <hr />
                                    <CardText>Give a Donation</CardText>
                                </button>
                            </Card>
                        </ul>
                    </div>
                    :
                    <div className="desktopButtons">
                        <ul className="buttonContainer">
                            <Card className="desktopButtonCards">
                                <button className="invButton" onClick={() => setModal(true)}>
                                    <CardTitle className="desktopButtonTitle"><FaRegPlayCircle size='70' /></CardTitle>
                                    <hr />
                                    <CardText>About the Project</CardText>
                                </button>
                            </Card>
                            <Card className="desktopButtonCards">
                                <a className="invButton" href="/#reflectionVideos">
                                    <CardTitle className="desktopButtonTitle"><FaGlasses size='70' /></CardTitle>
                                    <hr />
                                    <CardText>See Videos</CardText>
                                </a>
                            </Card>
                            <Card className="desktopButtonCards">
                                <button className="invButton" onClick={() => history.push('/thanks/submit')}>
                                    <CardTitle className="desktopButtonTitle"><FaPencilAlt size='70' /></CardTitle>
                                    <hr />
                                    <CardText>Leave a Thank You</CardText>
                                </button>
                            </Card>
                        </ul>
                    </div>
                }

                {path === "/thanks" ?
                    <Card className="mainCard" body>
                        <CardTitle className="mainCardTitle" tag="h2">Give Thanks</CardTitle>
                        <hr />
                        <CardText className="mainCardText" tag="h3">
                            Our dedicated team members have gone above and beyond to care for our community during the COVID-19 pandemic.
                            <br />
                            <br />
                            Share your reflection of this challenging time in history, and express your gratitude for their dedication, commitment, and compassion.
                            <br />
                            <br />
                            100% of all donations received will be used directly for employee wellbeing events and initiatives.
                        </CardText>
                        <br className="invBreak" />
                        <Button className="pillButton" onClick={() => setModal(true)}><FaRegPlayCircle size='40' />&nbsp;&nbsp;About the Project</Button>
                        <p className="invSpace" >&nbsp;</p>
                        <Button className="pillButton" href="/thanks#thankyouCards"><FaGlasses size='40' />&nbsp;&nbsp;See Thank You Cards</Button>
                        <p className="invSpace">&nbsp;</p>
                        <Button className="pillButton" onClick={() => history.push('/thanks/submit')}><FaPencilAlt size='40' />&nbsp;&nbsp;Leave a Thank You</Button>
                        <p className="invSpace">&nbsp;</p>
                        <Button className="pillButton" onClick={() => history.push('/thanks/donate')}><FaHandHoldingUsd size='40' />&nbsp;&nbsp;Give a Donation</Button>
                    </Card>
                    :
                    <Card className="mainCard" body>
                        <CardTitle className="mainCardTitle" tag="h2">Pandemic Reflections</CardTitle>
                        <hr />
                        <CardText className="mainCardText" tag="h3">
                            At a time when the world was shutting down, our dedicated team members had to quickly learn how to care for others when they needed it the most.
                            <br />
                            <br />
                            Teams from Meritus Health's frontlines reflect on how working through the COVID-19 pandemic has changed their lives forever.
                            <br />
                            <br />
                            Their stories are ones of loss, fear, exhaustion, hope, love, and faith, marking a moment in our history that relied on dedication, humanity, and compassion.
                        </CardText>
                        <br className="invBreak" />
                        <Button className="pillButton" onClick={() => setModal(true)}><FaRegPlayCircle size='40' />&nbsp;&nbsp;About the Project</Button>
                        <p className="invSpace">&nbsp;</p>
                        <Button className="pillButton" href="/#reflectionVideos"><FaGlasses size='40' />&nbsp;&nbsp;See Videos</Button>
                        <p className="invSpace">&nbsp;</p>
                        <Button className="pillButton" onClick={() => history.push('/thanks/submit')}><FaPencilAlt size='40' />&nbsp;&nbsp;Leave a Thank You</Button>
                    </Card>
                }
            </section>
            <div className="reflectionVideos" id="reflectionVideos">
                <p className="CaregiverTitle">Caregiver Reflections</p>
                <hr className='reflectionDivider' />
                <p className="blurb">These unscripted interviews offer unique perspectives at a time when health care workers were surrounded by fear, loss and exhaustion, but somehow found hope through it all.</p>
                <Container className="carouselContainer">
                    <VideoCarousel />
                </Container>
            </div>
            <div id="thankyouCards" className="thankyouCards" style={{ backgroundColor: "rgba(81, 145, 205)" }}>
                <p className="thanksTitle" style={{
                    textAlign: 'center', fontSize: 40, paddingTop: 30, color: 'white', fontWeight: 'bold'
                }}>We recognize all that you do for our community. Thank you!</p>
                {thankyousList.length !== 0 &&
                    <Pagination
                        data={thankyousList}
                        RenderComponent={ThankYouCards}
                        title="Posts"
                        pageLimit={numPages}
                        dataLimit={20}
                    />
                }
            </div>
        </div>
    )
}