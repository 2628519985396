import * as React from 'react';
import { Card, CardBody, CardSubtitle, CardText } from 'reactstrap';
import './css/ThankYouCards.css'

export const ThankYouCards = (props: any) => {
    return (
        <Card className="cards">
            <CardBody>
                <CardText className="mainText">{props.data.message}</CardText>
                <hr className='cardDivider'/>
                <CardSubtitle tag="h6" className="subText mb-2">{props.data.firstName}</CardSubtitle>
            </CardBody>
        </Card>
    );
}
