import * as React from 'react';
import { Route } from 'react-router';
import { DonateForm } from './components/DonateForm';
import Layout from './components/Layout';
import { Reflections } from './components/Reflections';
import { ThankYouForm } from './components/ThankYouForm';
import { ApproveForm } from './components/ApproveForm';
import { DenyForm } from './components/DenyForm';
import { Helmet } from "react-helmet";

import './custom.css'

export default () => (
    <Layout>
        <Helmet>
            <script defer data-domain="reflections.meritushealth.com" src="https://plausible.meritushealth.com/js/script.js"></script>
        </Helmet>
        <Route exact path='/' component={Reflections} />
        <Route exact path='/thanks' component={Reflections} />
        <Route exact path='/about' component={Reflections} />
        <Route exact path='/thanks/submit' component={ThankYouForm} />
        <Route exact path='/thanks/donate' component={DonateForm} />
        <Route exact path='/approveForm/:encryptedString' component={ApproveForm} />
        <Route exact path='/denyForm/:encryptedString' component={DenyForm} />
    </Layout>
);